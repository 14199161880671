import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import { ToWords } from "to-words";
import MDButton from "components/MDButton";
import "./IndianRupeeFont-normal";
import font from "./NotoSans-Regular-normal";

const InvoiceGenerator = ({ id }) => {
  const orderId = id;
  const [stateName, setStateName] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState("");

  const [records, setRecords] = useState([]);
  const [cds, setCds] = useState([]);
  const [cassettes, setCassetes] = useState([]);
  const [liquids, setLiquids] = useState([]);

  const TAX_RATE = 0.18;
  const CGST_RATE = 0.09;
  const SGST_RATE = 0.09;

  const calculatePreTaxPrice = (sellingPrice) =>
    Number((sellingPrice / (1 + TAX_RATE)).toFixed(2));

  const roundToTwo = (num) => Number(num.toFixed(2));

  const fetchStateByPincode = async (pincode) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (response.data[0].Status === "Success") {
        return response.data[0].PostOffice[0].State;
      } else {
        throw new Error("Invalid pincode");
      }
    } catch (error) {
      console.error("Failed to fetch state information:", error);
      return null;
    }
  };

  const fetchOrderData = async () => {
    try {
      const response = await axios.post(
        "https://api.panchamrecords.com/order/getorder",
        { order_id: orderId },
        { headers: { "Content-Type": "application/json" } }
      );

      const result = response.data;
      if (result.success) {
        setOrderData(result.data.orders);
        performCalculations(result.data.orders);
        return result.data.orders.pincode;
      } else {
        throw new Error("Failed to fetch order data");
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      setError("Failed to fetch order data.");
      return null;
    }
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });

  const convertToWords = (num) => {
    return toWords.convert(num, { currency: true });
  };

  const performCalculations = (order) => {
    let recordItems = [];
    let cdItems = [];
    let cassetteItems = [];
    let liquidItems = [];

    order.order_items.forEach((item) => {
      const { product_details, quantity, total_price } = item;
      const {
        product_type,
        title,
        condition_type,
        selling_price,
        catalogue_details,
      } = product_details;

      // Calculate rate, total_amount, total_taxable_value, total_tax_value, etc.
      const rate = (selling_price / 1.18).toFixed(2);
      const total_amount = (rate * quantity).toFixed(2);
      const total_taxable_value = parseFloat(total_amount).toFixed(2);
      const total_tax_value = (total_taxable_value / 1.18).toFixed(2);
      const total_tax = (total_taxable_value - total_tax_value).toFixed(2);

      // CGST and SGST (if applicable)
      const total_tax_cgst = (total_taxable_value * 0.09).toFixed(2);
      const total_tax_sgst = (total_taxable_value * 0.09).toFixed(2);

      // IGST (if applicable)
      const total_tax_igst = (total_taxable_value * 0.18).toFixed(2);

      // Combine all catalogue ids
      const catalogue_ids = catalogue_details
        .map((c) => c.catalogue_id)
        .join(",");

      // Assign HSN based on product type
      let hsn = null;
      if (product_type === "Vinyl Record") {
        hsn = "85238010";
      } else if (product_type === "Liquid") {
        hsn = "2905";
      }

      // Object to store calculated values
      const product = {
        product_type,
        title,
        condition_type,
        selling_price: selling_price.toFixed(2),
        quantity,
        total_price: total_price.toFixed(2),
        rate: rate,
        total_amount: total_amount,
        total_taxable_value: total_taxable_value,
        total_tax_value: total_tax_value,
        total_tax: total_tax,
        total_tax_cgst: total_tax_cgst,
        total_tax_sgst: total_tax_sgst,
        total_tax_igst: total_tax_igst,
        catalogue_ids: catalogue_ids,
        hsn,
      };

      // Group by product type
      if (product_type === "Vinyl Record") {
        recordItems.push(product);
      } else if (product_type === "CD") {
        cdItems.push(product);
      } else if (product_type === "Cassette") {
        cassetteItems.push(product);
      } else if (product_type === "Liquid") {
        liquidItems.push(product);
      }
    });

    // Set the state for different product types
    setRecords(recordItems);
    setCds(cdItems);
    setCassetes(cassetteItems);
    setLiquids(liquidItems);
  };

  const generatePDF = (type) => {
    if (!orderData) return;

    const doc = new jsPDF({
      format: "a5",
      unit: "mm",
      orientation: "portrait",
    });

       doc.addFileToVFS("NotoSans-Regular.ttf", font);
       doc.addFont("NotoSans-Regular.ttf", "NotoSans", "normal");

       // Set the font
       doc.setFont("NotoSans");

    const addText = (text, x, y, size = 8, style = "normal") => {
      doc.setFontSize(size);

      // doc.setFont("IndianRupeeFont", style);
      doc.text(text, x, y);
    };

    addText("Tax Invoice", 65, 10, 10, "bold");
    addText(`(${type})`, 110, 10, 8, "normal");

    doc.autoTable({
      startY: 20,
      theme: "grid",
      styles: {
        fontSize: 6,
        cellPadding: 1,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        font: "NotoSans",
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      bodyStyles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      didDrawCell: (data) => {
        if (data.section === "body" && data.row.index < 2) {
          doc.setLineWidth(0.1);
          doc.setDrawColor(0, 0, 0);
          doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
        }
      },
      columnStyles: {
        0: { cellWidth: 60, halign: "left" },
        1: { cellWidth: 30, halign: "left" },
        2: { cellWidth: 30, halign: "left" },
      },
      body: [
        [
          {
            content:
              "Pancham Records\n" +
              "Khardekar Complex ,\n" +
              "Opp Mission Hospital ,\n" +
              "Jalna 431203, Maharashtra\n" +
              "GSTIN/UIN: 27AYJPJ2968F1Z5\n" +
              "Mobile : 9422577724\n" +
              "E-Mail : sales@panchamrecords.com",
            styles: { fontSize: 6 },
          },
          {
            content: `Invoice No.\n\n${orderData.billing_id}\n\n`,
            styles: { fontSize: 6 },
          },
          {
            content: `Dated\n\n${new Date(
              orderData.order_date
            ).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}\n\n`,
            styles: { fontSize: 6 },
          },
        ],
        [
          {
            content:
              `Buyer Details\n\n` +
              `${orderData.full_name}\n` +
              `${orderData.street1}, ${orderData.street2}\n` +
              `${orderData.city}, ${orderData.pincode}\n` +
              `State Name     : ${stateName}\n` +
              `Mobile Number : ${orderData.address_details.mobile_no}\n`,
            styles: { fontSize: 6 },
          },
          {
            content: "Mode of Payment\n\nOnline\n\n",
            styles: { fontSize: 6 },
          },
        ],
      ],
    });

    const calculateRoundOff = (actualTotal) => {
      const roundedTotal = Math.round(actualTotal); // Round to nearest integer
      const roundOff = roundToTwo(roundedTotal - actualTotal); // Calculate round-off difference
      return { roundedTotal, roundOff };
    };

    const calculateInvoice = (orderData, stateName) => {
      const headers = [
        [
          "Sl No",
          "Description of Goods",
          "HSN/SAC",
          "Quantity",
          "Rate",
          "Total Amount",
        ],
      ];

      const allProductTypes = [...records, ...cds, ...cassettes, ...liquids];

      const data = allProductTypes.map((item, index) => {
        const {
          product_type,
          title,
          condition_type,
          hsn,
          quantity,
          rate,
          total_amount,
          catalogue_ids,
        } = item;

        return [
          index + 1,
          `${product_type}\n${title} (${condition_type}) ${
            type === "Seller Copy" ? `[${catalogue_ids}]` : ``} `,
          hsn || "N/A",
          `${quantity} No`,
          `₹ ${rate}`,
          `₹ ${total_amount}`,
        ];
      });

      const totalPreTaxAmount = allProductTypes.reduce(
        (acc, item) => acc + parseFloat(item.total_amount),
        0
      );

      let taxAmount;
      let taxRows;
      if (stateName === "Maharashtra") {
        const totalTaxCgst = roundToTwo(totalPreTaxAmount * 0.09);
        const totalTaxSgst = roundToTwo(totalPreTaxAmount * 0.09);
        taxAmount = totalTaxCgst + totalTaxSgst;

        taxRows = [
          [
            "",
            { content: "CGST @ 9%", styles: { halign: "right" } },
            "",
            "",
            "9%",
            `₹ ${totalTaxCgst}`,
          ],
          [
            "",
            { content: "SGST @ 9%", styles: { halign: "right" } },
            "",
            "",
            "9%",
            `₹ ${totalTaxSgst}`,
          ],
        ];
      } else {
        const totalTaxIgst = roundToTwo(totalPreTaxAmount * 0.18);
        taxAmount = totalTaxIgst;

        taxRows = [
          [
            "",
            { content: "IGST @ 18%", styles: { halign: "right" } },
            "",
            "",
            "18%",
            `₹ ${totalTaxIgst}`,
          ],
        ];
      }

      const actualTotal = roundToTwo(totalPreTaxAmount + taxAmount);

      // Use the new round-off logic
      const { roundedTotal, roundOff } = calculateRoundOff(actualTotal);

      const totalQuantity = allProductTypes.reduce(
        (acc, item) => acc + parseInt(item.quantity),
        0
      );

      data.push(
        ...taxRows,
        [
          "",
          { content: "Courier Charges", styles: { halign: "right" } },
          "996812",
          "",
          "",
          `₹ ${roundToTwo(orderData.billing_amount - roundedTotal)}`,
        ],
        [
          "",
          { content: "ROUND OFF", styles: { halign: "right" } },
          "",
          "",
          "",
          `₹ ${roundOff}`,
        ],
        [
          "",
          { content: "Total", styles: { halign: "right", fontStyle: "bold" } },
          "",
          `${totalQuantity} No`,
          "",
          {
            content: `₹ ${roundToTwo(orderData.billing_amount)}`,
            styles: { fontStyle: "bold" },
          },
        ]
      );

      return {
        headers,
        data,
        totalPreTaxAmount,
        taxAmount,
        roundedTotal,
        roundOff,
      };
    };

    // Calling the calculateInvoice function
    const {
      headers,
      data,
      totalPreTaxAmount,
      taxAmount,
      roundedTotal,
      roundOff,
    } = calculateInvoice(orderData, stateName);

    // Render the table using autoTable (as in the original code)
    doc.autoTable({
      head: headers,
      body: data,
      startY: doc.lastAutoTable.finalY + 5,
      theme: "grid",
      styles: {
        fontSize: 6,
        cellPadding: 1,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        font: "NotoSans",
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        halign: "center",
      },
      bodyStyles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      didDrawCell: (data) => {
        doc.setLineWidth(0.1);
        doc.setDrawColor(0, 0, 0);
        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      },
      columnStyles: {
        0: { cellWidth: 7, halign: "left" },
        1: { cellWidth: 50, halign: "left" },
        2: { cellWidth: 18, halign: "center" },
        3: { cellWidth: 12, halign: "center" },
        4: { cellWidth: 15, halign: "center" },
        5: { cellWidth: 18, halign: "right" },
      },
    });

    let y = doc.lastAutoTable.finalY + 2;
    y += 5;
    addText("Amount Chargeable (in words):", 14, y, 6, "normal");
    addText("E. & O.E", 125, y, 6, "bold");
    y += 3;
    addText(convertToWords(roundedTotal), 14, y, 6, "bold");

    y += 5;
    doc.autoTable({
      head:
        stateName === "Maharashtra"
          ? [
              [
                "HSN/SAC",
                "Taxable Value",
                "CGST\n9%",
                "SGST\n9%",
                "Total Tax Amount",
              ],
            ]
          : [["HSN/SAC", "Taxable Value", "IGST\n18%", "Total Tax Amount"]],
      body: (() => {
        // Group products by HSN codes for summation
        const allProductTypes = [...records, ...cds, ...cassettes, ...liquids];

        const productsByHSN = allProductTypes.reduce((acc, item) => {
          const {
            hsn,
            total_taxable_value,
            total_tax_cgst,
            total_tax_sgst,
            total_tax_igst,
          } = item;

          // Ensure all values are treated as numbers before summing
          const taxableValue = Number(total_taxable_value) || 0;
          const cgst = Number(total_tax_cgst) || 0;
          const sgst = Number(total_tax_sgst) || 0;
          const igst = Number(total_tax_igst) || 0;

          if (!acc[hsn]) {
            acc[hsn] = {
              taxableValue: 0,
              totalTaxCgst: 0,
              totalTaxSgst: 0,
              totalTaxIgst: 0,
              taxAmount: 0,
            };
          }

          acc[hsn].taxableValue += taxableValue;
          if (stateName === "Maharashtra") {
            acc[hsn].totalTaxCgst += cgst;
            acc[hsn].totalTaxSgst += sgst;
            acc[hsn].taxAmount += cgst + sgst;
          } else {
            acc[hsn].totalTaxIgst += igst;
            acc[hsn].taxAmount += igst;
          }
          console.log(allProductTypes);
          console.log(acc);

          return acc;
        }, {});

        // Get delivery charges if present and ensure it's a number
        const deliveryCharges = roundToTwo(
          orderData.billing_amount - roundedTotal
        );

        // Create rows dynamically for each HSN
        const rows = Object.entries(productsByHSN).map(([hsn, values]) => {
          return stateName === "Maharashtra"
            ? [
                hsn,
                `₹ ${roundToTwo(values.taxableValue)}`,
                `₹ ${roundToTwo(values.totalTaxCgst)}`,
                `₹ ${roundToTwo(values.totalTaxSgst)}`,
                `₹ ${roundToTwo(values.taxAmount)}`,
              ]
            : [
                hsn,
                `₹ ${roundToTwo(values.taxableValue)}`,
                `₹ ${roundToTwo(values.totalTaxIgst)}`,
                `₹ ${roundToTwo(values.taxAmount)}`,
              ];
        });

        if (deliveryCharges !== 0) {
          rows.push([
            "996812",
            `₹ ${roundToTwo(deliveryCharges)}`,
            stateName === "Maharashtra"
              ? `₹ ${roundToTwo(deliveryCharges * 0.09)}`
              : `₹ ${roundToTwo(deliveryCharges * 0.18)}`,
            stateName === "Maharashtra"
              ? `₹ ${roundToTwo(deliveryCharges * 0.09)}`
              : "",
            stateName === "Maharashtra"
              ? `₹ ${roundToTwo(deliveryCharges * 0.09 * 2)}`
              : `₹ ${roundToTwo(deliveryCharges * 0.18)}`,
          ]);
        }

        // Calculate total taxable value and total tax amount
        const totalTaxableValue = Object.values(productsByHSN).reduce(
          (acc, curr) => acc + Number(curr.taxableValue),
          0
        );
        const totalTaxAmount =
          Object.values(productsByHSN).reduce(
            (acc, curr) => acc + Number(curr.taxAmount),
            0
          ) +
          roundToTwo(
            deliveryCharges * (stateName === "Maharashtra" ? 0.18 : 0.18)
          );

        // Add total row at the end
        rows.push([
          {
            content: "Total",
            styles: { halign: "right", fontStyle: "bold" },
          },
          `₹ ${roundToTwo(totalTaxableValue + deliveryCharges)}`,
          "",

          {
            content: `₹ ${roundToTwo(totalTaxAmount)}`,
            styles: { fontStyle: "bold" },
          },
        ]);

        return rows;
      })(),
      startY: doc.lastAutoTable.finalY + 15,
      theme: "grid",
      styles: {
        fontSize: 6,
        cellPadding: 1,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        font: "NotoSans",
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        halign: "center",
      },
      bodyStyles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      didDrawCell: (data) => {
        doc.setLineWidth(0.1);
        doc.setDrawColor(0, 0, 0);
        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
      },
      columnStyles:
        stateName === "Maharashtra"
          ? {
              0: { cellWidth: 37, halign: "left" },
              1: { cellWidth: 25, halign: "center" },
              2: { cellWidth: 18, halign: "center" },
              3: { cellWidth: 18, halign: "center" },
              4: { cellWidth: 22, halign: "right" },
            }
          : {
              0: { cellWidth: 55, halign: "left" },
              1: { cellWidth: 25, halign: "center" },
              2: { cellWidth: 18, halign: "center" },
              4: { cellWidth: 22, halign: "right" },
            },
    });

    y = doc.lastAutoTable.finalY + 5;
    addText("Tax Amount (in words):", 14, y, 6, "normal");
    y += 3;
    addText(convertToWords(taxAmount), 14, y, 6, "bold");
    y += 20;
    addText("Subject to Jalna Jurisdiction", 14, y, 6);

    addText("For Pancham Records", 111, y, 6);
    y += 15;

    addText("This is a Computer Generated Invoice", 60, y, 6);

    doc.save(
      `${orderData.billing_id}-${type === "Buyer Copy" ? "b" : "s"}.pdf`
    );
  };

  const handleButtonClick = async (type) => {
    try {
      const pincode = await fetchOrderData();
      const state = await fetchStateByPincode(pincode);
      setStateName(state);
      generatePDF(type);
    } catch (error) {
      console.error("An error occurred during the process:", error);
      setError("An error occurred while processing.");
    }
  };

  return (
    <div>
      <MDButton
        onClick={() => handleButtonClick("Buyer Copy")}
        variant="outlined"
        color="white"
      >
        Buyer Tax Invoice
      </MDButton>
      <MDButton
        onClick={() => handleButtonClick("Seller Copy")}
        variant="outlined"
        color="white"
      >
        Seller Tax Invoice
      </MDButton>
    </div>
  );
};

export default InvoiceGenerator;
