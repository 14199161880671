import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Grid,
  FormControlLabel,
  Typography,
  TextField,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Checkbox from "@mui/material/Checkbox";
import SelectArtist from "components/SelectArtist/SelectArtist";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useMediaQuery } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import {
  DeleteForeverOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";

import Autocomplete from "@mui/material/Autocomplete";
import MDDialog from "components/MDDialog/MDDialog";
import AddInventoryModal from "components/MDDialog/AddInventoryDialog";
import ProductToggle from "./ProductToggle";

const seriesData = [
  { series_id: 1, series_name: "PRO" },
  { series_id: 2, series_name: "PRN" },
  { series_id: 3, series_name: "DAG" },
  { series_id: 4, series_name: "DAP" },
  { series_id: 5, series_name: "BMC" },
];

function AddProduct({ props }) {
  const { id } = useParams();
  const auth = localStorage.getItem("authToken");
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    user_id: "3",
    category: "",
    temp_category: "",
    description: "",
    title: "",
    details: [],
    mrp: 0,
    selling_price: 0,
    qty_available: 0,
    qty_sold: 0,
    catalogue_number: "",
    max_quantity: 0,
    images: [],
    condition_type: "",
    artists: [],
    lyricists: [],
    music_director: [],
    series_id: "",
    product_type: "",
    condition_details: [],
    type: "",
    label: "",
    country: "",
    year: "",
    tag: "",
    language: "",
    combo: "",
    cd_details: [],
    catalogue: [],
    is_active : 1
  });

  const [loading, setLoading] = useState(false);
  const [tempImages, setTempImages] = useState([]);
  const [seriesID, setSeriesID] = useState([]);

  const [artistsArray, setArtistsArray] = useState([]);
  const [lyricistArray, setLyricistArray] = useState([]);
  const [mDirectorsArray, setMDirectorsArray] = useState([]);
  const [latestSeriesID, setLatestSeriesID] = useState("");

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleRadioClick = (e) => {
    const value = e.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      tag: prevValues.tag === value ? "" : value
    }));
  };
  useEffect(() => {
    getSeries()
  }, [])

  useEffect(() => {
    if (id) {
      getProduct();
    }
    if (auth) {
      const decodedJwt = JSON.parse(atob(auth.split(".")[1]));
      if (decodedJwt.exp * 1000 < Date.now()) {
        navigate("/sign-in");
      }
    }
  }, [id, auth]);
  console.log(tempImages)
  const getProduct = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getproduct`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          setValues({
            ...values,
            category: res.data.data.category,
            description: res.data.data.description,
            details: res.data.data.details,
            title: res.data.data.title,
            mrp: res.data.data.mrp,
            selling_price: res.data.data.selling_price,
            qty_available: res.data.data.qty_available,
            qty_sold: res.data.data.qty_sold,
            catalogue_number: res.data.data.catalogue_number,
            max_quantity: res.data.data.max_quantity,
            images: res.data.data.product_images,
            condition_type: res.data.data.condition_type,
            artists: res.data.data.artists,
            lyricists: res.data.data.lyricists,
            music_director: res.data.data.music_director,
            series_id: res.data.data.series_id,
            product_type: res.data.data.product_type,
            type: res.data.data.type,
            label: res.data.data.label,
            country: res.data.data.country,
            year: res.data.data.year,
            cd_details: res.data.data.cd_details,
            catalogue: res.data.data.catalogue,
            tag: res.data.data.tag,
            language: res.data.data.language,
            combo: res.data.data.combo,
is_active: res.data.data.is_active
          });
          setArtistsArray(
            res.data.data.artists.map((singer) => ({ name: singer }))
          );
          setLyricistArray(
            res.data.data.lyricists.map((singer) => ({ name: singer }))
          );
          setMDirectorsArray(
            res.data.data.music_director.map((singer) => ({ name: singer }))
          );
          setFormData(res.data.data.type);
          setDetailsObject(res.data.data.details);
          setTempImages(res.data.data.product_images);
          setConditionDetails(res.data.data.condition_details);
          enqueueSnackbar("Product Fetched successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Failed", { variant: "error" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const getLatestSeriesID = async (id) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getprevseries`,
        {
          series_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setLatestSeriesID(
            res.data.data.series_name + res.data.data.Latest_copy_ID
          );
        } else {
          enqueueSnackbar("Failed", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      });
  };

  const getSeries = async (id) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASEURL}/admin/series/getall`,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setSeriesID(
            res.data.data
          );
        } else {
          enqueueSnackbar("Failed", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values.title || !values.description) {
      enqueueSnackbar("Please Provide All The Details !!!", {
        variant: "error",
      });
      return;
    }

    setLoading(true);

    const requestData = new FormData();
    requestData.append("title", values.title);
    requestData.append("user_id", 2);
    requestData.append(
      "category",
      values.temp_category ? values.temp_category : values.category
    );
    requestData.append("details", JSON.stringify(detailsObject));
    requestData.append("mrp", values.mrp);
    requestData.append("selling_price", values.selling_price);
    requestData.append("qty_available", values.qty_available);
    requestData.append("qty_sold", values.qty_sold);
    requestData.append("catalogue_number", values.catalogue_number);
    requestData.append("max_quantity", values.max_quantity);
    requestData.append("description", values.description);
    requestData.append("condition_type", values.condition_type);
    requestData.append(
      "artists",
      JSON.stringify(artistsArray.map((item) => item.name))
    );
    requestData.append(
      "lyricists",
      JSON.stringify(lyricistArray.map((item) => item.name))
    );
    requestData.append(
      "music_director",
      JSON.stringify(mDirectorsArray.map((item) => item.name))
    );
    requestData.append("series_id", values.series_id);
    requestData.append("product_type", values.product_type);
    requestData.append("condition_details", JSON.stringify(conditionDetails));
    requestData.append("type", JSON.stringify(formData));
    requestData.append("label", values.label);
    requestData.append("country", values.country);
    requestData.append("year", values.year);
    requestData.append("tag", values.tag);
    requestData.append("language", values.language);
    requestData.append("combo", values.combo);

    if (id) {
      requestData.append("product_images", JSON.stringify(tempImages));
      requestData.append("product_id", id);
    }

    if (selectedImagesFiles) {
      Array.from(selectedImagesFiles).forEach((image) => {
        requestData.append("images", image);
      });
    }

    await axios({
      method: id ? "put" : "post",
      url: `${process.env.REACT_APP_BASEURL}/admin/product/${id ? "updateproduct" : "add"
        }`,
      data: requestData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Operation successful", {
            variant: "success",
          });
          if (id) {
            navigate(`/products`);
          } else {
            navigate(`/products/${res.data.data.product_id}/media`);
          }
        } else {
          enqueueSnackbar("Operation failed", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error("Error occurred:", err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Use useEffect to listen for the Enter key globally
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleSubmit(e);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]);
  
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesFiles, setSelectedImagesFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedImagesFiles(files);

    const selectedImageArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedImages(selectedImageArray);
  };

  const handleSwap1 = (index1, index2) => {
    let newImages = [...tempImages];
    [newImages[index1], newImages[index2]] = [newImages[index2], newImages[index1]];
    values.images = (newImages);
    setTempImages(newImages);
  };

  const handleSwap = (index1, index2) => {
    const updatedSelectedFiles = [...selectedImagesFiles];
    const updatedSelectedImages = [...selectedImages];

    [updatedSelectedFiles[index1], updatedSelectedFiles[index2]] = [
      updatedSelectedFiles[index2],
      updatedSelectedFiles[index1],
    ];

    [updatedSelectedImages[index1], updatedSelectedImages[index2]] = [
      updatedSelectedImages[index2],
      updatedSelectedImages[index1],
    ];

    setSelectedImagesFiles(updatedSelectedFiles);
    setSelectedImages(updatedSelectedImages);
  };

  const [detailsObject, setDetailsObject] = useState([
    {
      key: "",
      description: "",
    },
  ]);

  const [conditionDetails, setConditionDetails] = useState([
    {
      key: "Condition of Cover",
      description: "",
    },
    {
      key: "Condition of Record",
      description: "",
    },
  ]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 600,
    },
    {
      field: "onclick",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <strong>
          <MDButton
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/products/${params.row.product_id}/media`, {
                state: {
                  cdDetails: params.row,
                  type:
                    values.product_type === "Vinyl Record"
                      ? "Record"
                      : values.product_type === "CD"
                        ? "CD"
                        : "Cassete",
                },
              });
            }}
          >
            Open
          </MDButton>
        </strong>
      ),
    },
  ];

  const catalogueColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },

    {
      field: "series_id",
      headerName: "Series ID",
      width: 300,
    },
    {
      field: "catalogue_id",
      headerName: "Catalogue ID",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "billing_id",
      headerName: "Invoice ID",
      width: 200,
    },
  ];

  const addObject = (setState, currentState) => {
    setState([...currentState, { key: "", description: "" }]);
  };

  const removeObject = (setState, currentState, index) => {
    const newData = [...currentState];
    newData.splice(index, 1);
    setState(newData);
  };

  const handleObjectChange = (
    setState,
    currentState,
    index,
    fieldName,
    value
  ) => {
    setState((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [fieldName]: value,
      };
      return newData;
    });
  };

  const [formData, setFormData] = useState({
    cdType: {
      Silver: false,
      Gold: false,
      Regular: false,
      Boxset: false,
      type: "",
    },
    casetteType: "Normal",
    vinylRecordType: {
      recordCat1: "",
      rpm: "",
      color: "Black",
    },
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => {
      if (name === "cdType") {
        return {
          ...prevData,
          cdType: { ...prevData.cdType, [value]: !prevData.cdType[value] },
        };
      } else if (name === "cdTypeText") {
        return {
          ...prevData,
          cdType: { ...prevData.cdType, type: [value] },
        };
      } else if (name === "casetteType") {
        return { ...prevData, casetteType: value };
      } else if (name.startsWith("vinylRecordType")) {
        const [parentName, childName] = name.split(".");
        return {
          ...prevData,
          vinylRecordType: {
            ...prevData.vinylRecordType,
            [childName]: value,
          },
        };
      }
      return prevData;
    });
  };

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [addInventoryModal, setAddInventoeyModal] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenAddInventoryModal = () => {
    setAddInventoeyModal(true);
  };

  const handleCloseAddInventoryModal = () => {
    setAddInventoeyModal(false);
  };

  const isValidCategory = (category) => {
    const predefinedCategories = ["Movie Sound Track", "Compilation", "Album"];
    return predefinedCategories.includes(category);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />
      <MDDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        productId={id}
      />

      <AddInventoryModal
        open={addInventoryModal}
        onClose={handleCloseAddInventoryModal}
        productId={id}
      />

      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  {props.type === "update"
                    ? "Update Product"
                    : "Create Product"}
                </MDTypography>

                <MDBox className="ticket_btnsHeaderCont">
                  {props.type === "update" &&
                    localStorage.getItem("role") === "admin" && (
                      <>
                        <ProductToggle productId={id} initialIsActive={values.is_active} />

                        <MDButton
                          onClick={() => handleOpenAddInventoryModal()}
                          variant="outlined"
                          color="white"
                          sx={{ marginRight: "5px" }}
                        >
                          Add Inventory
                        </MDButton>
                        <MDButton
                          onClick={() => handleOpenDialog()}
                          variant="outlined"
                          color="white"
                          sx={{ marginRight: "5px" }}
                        >
                          Store Sale
                        </MDButton>
                        <MDButton
                          onClick={() =>
                            navigate(`/products/${id}/media`, {
                              state: {
                                type:
                                  values.product_type === "Vinyl Record"
                                    ? "Record"
                                    : values.product_type === "CD"
                                    ? "CD"
                                    : "Cassete",
                              },
                            })
                          }
                          variant="outlined"
                          color="white"
                        >
                          Add{" "}
                          {values.product_type === "Vinyl Record"
                            ? "Records"
                            : values.product_type === "CD"
                            ? "CDs"
                            : "Cassetes"}
                        </MDButton>
                      </>
                    )}
                </MDBox>
              </MDBox>

              <MDBox p={3} component="form" role="form">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    <FormControl style={{ flex: 1, marginRight: "10px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Condition Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.condition_type}
                        name="condition_type"
                        label="Condition Type"
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        sx={{ height: 45 }}
                      >
                        <MenuItem value={"New"}>New</MenuItem>
                        <MenuItem value={"Preowned"}>Preowned</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{ flex: 1, marginRight: "10px" }}>
                      <InputLabel>Product Type</InputLabel>
                      <Select
                        labelId="product-type"
                        id="product-type"
                        value={values.product_type}
                        name="product_type"
                        label="Product Type"
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        sx={{ height: 45 }}
                      >
                        <MenuItem value={"Liquid"}>Liquid</MenuItem>
                        <MenuItem value={"CD"}>CD</MenuItem>
                        <MenuItem value={"Cassette"}>Cassette</MenuItem>
                        <MenuItem value={"Vinyl Record"}>Vinyl Record</MenuItem>
                      </Select>
                    </FormControl>

                    {
                      <FormControl style={{ flex: 1 }}>
                        <InputLabel id="category">Category</InputLabel>
                        <Select
                          labelId="category"
                          id="category"
                          value={values.category}
                          name="category"
                          label="Category"
                          onChange={(e) => handleChange(e)}
                          fullWidth
                          sx={{ height: 45 }}
                        >
                          <MenuItem value={"Movie Sound Track"}>
                            Movie Sound Track
                          </MenuItem>
                          <MenuItem value={"Compilation"}>Compilation</MenuItem>
                          <MenuItem value={"Album"}>Album</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    }
                    {(values.category === "Other" ||
                      (id && !isValidCategory(values.category))) && (
                      <div>
                        <MDBox p={1}>
                          <MDInput
                            type="text"
                            label="Category"
                            value={values.temp_category}
                            name="temp_category"
                            margin="dense"
                            fullWidth
                            onChange={handleChange}
                          />
                        </MDBox>
                      </div>
                    )}
                  </div>

                  {values.condition_type === "Preowned" && (
                    <>
                      <MDBox p={1}>
                        <MDTypography variant="button" color="dark">
                          Condition Details
                        </MDTypography>
                        {conditionDetails?.map((group, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <MDInput
                              // label="Key"
                              value={group.key}
                              disabled
                              onChange={(e) =>
                                handleObjectChange(
                                  setConditionDetails,
                                  conditionDetails,
                                  index,
                                  "key",
                                  e.target.value
                                )
                              }
                              style={{ marginRight: "10px", flex: 1 }}
                            />
                            <MDInput
                              // label="Description"
                              value={group.description}
                              onChange={(e) =>
                                handleObjectChange(
                                  setConditionDetails,
                                  conditionDetails,
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                              style={{ marginRight: "10px", flex: 2 }}
                            />
                            {/* <MDButton
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                removeObject(
                                  setConditionDetails,
                                  conditionDetails,
                                  index
                                )
                              }
                            >
                              Remove
                            </MDButton> */}
                          </div>
                        ))}
                      </MDBox>
                      {/* <div style={{ textAlign: "right", margin: "10px" }}>
                        <MDButton
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            addObject(setConditionDetails, conditionDetails)
                          }
                          sx={{ textAlign: "center" }}
                        >
                          Add
                        </MDButton>
                      </div> */}
                    </>
                  )}
                  <MDBox p={1}>
                    <MDInput
                      type="text"
                      label="Title"
                      value={values.title}
                      name="title"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <MDInput
                      type="text"
                      multiline
                      rows={3}
                      label="Description"
                      value={values.description}
                      name="description"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  {values.product_type === "CD" && (
                    <MDBox p={1}>
                      <MDTypography variant="button" color="dark">
                        CD Type
                      </MDTypography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox checked={formData.cdType.Silver} />
                          }
                          label="Silver"
                          onChange={() => handleInputChange("cdType", "Silver")}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={formData.cdType.Gold} />}
                          label="Gold"
                          onChange={() => handleInputChange("cdType", "Gold")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={formData.cdType.Regular} />
                          }
                          label="Regular"
                          onChange={() =>
                            handleInputChange("cdType", "Regular")
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={formData.cdType.Boxset} />
                          }
                          label="Boxset"
                          onChange={() => handleInputChange("cdType", "Boxset")}
                        />
                        <MDInput
                          value={formData.cdType.type}
                          label="CD Type"
                          style={{ marginRight: "10px", flex: 1 }}
                          onChange={(e) =>
                            handleInputChange("cdTypeText", e.target.value)
                          }
                        />
                      </div>
                    </MDBox>
                  )}
                  {/* cassette Type */}
                  {values.product_type === "Cassette" && (
                    <MDBox p={1}>
                      <MDTypography variant="button" color="dark">
                        Cassette Type
                      </MDTypography>
                      <RadioGroup
                        row
                        aria-labelledby="cassette-type"
                        name="cassette-type"
                        value={formData.casetteType}
                        onChange={(e) =>
                          handleInputChange("casetteType", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Normal"
                          control={<Radio />}
                          label="Normal"
                        />
                        <FormControlLabel
                          value="Chrome"
                          control={<Radio />}
                          label="Chrome"
                        />
                        <FormControlLabel
                          value="Metal"
                          control={<Radio />}
                          label="Metal"
                        />
                      </RadioGroup>
                    </MDBox>
                  )}
                  {/* Vinyl Record Type */}
                  {values.product_type === "Vinyl Record" && (
                    <MDBox p={1}>
                      <MDTypography variant="button" color="dark">
                        Vinyl Record Type
                      </MDTypography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <FormControl style={{ flex: 1 }}>
                          <InputLabel id="category">Category</InputLabel>
                          <Select
                            value={formData.vinylRecordType.recordCat1}
                            name="vinylRecordType.recordCat1"
                            label="Category 1"
                            fullWidth
                            sx={{ height: 45 }}
                            onChange={(e) =>
                              handleInputChange(
                                "vinylRecordType.recordCat1",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={`LP/ 12"`}>LP/ 12"</MenuItem>
                            <MenuItem value={`EP/SP/7"`}>EP/SP/7"</MenuItem>
                            <MenuItem value={`10"`}>10" </MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl style={{ flex: 1, marginLeft: "10px" }}>
                          <InputLabel id="category">RPM</InputLabel>
                          <Select
                            name="vinylRecordType.rpm"
                            value={formData.vinylRecordType.rpm}
                            label="RPM"
                            fullWidth
                            sx={{ height: 45 }}
                            onChange={(e) =>
                              handleInputChange(
                                "vinylRecordType.rpm",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value={"33 1/3"}>33 1/3</MenuItem>
                            <MenuItem value={"45"}>45</MenuItem>
                            <MenuItem value={"78"}>78 </MenuItem>
                          </Select>
                        </FormControl>
                        <MDInput
                          label="Color"
                          value={formData.vinylRecordType.color}
                          style={{ flex: 1, marginLeft: "10px" }}
                          onChange={(e) =>
                            handleInputChange(
                              "vinylRecordType.color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </MDBox>
                  )}
                  <MDBox p={1}>
                    <MDInput
                      type="text"
                      label="Label"
                      value={values.label}
                      name="label"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <MDInput
                      type="text"
                      label="Year"
                      value={values.year}
                      name="year"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <FormControl fullWidth>
                      <InputLabel id="">Series ID</InputLabel>

                      <Select
                        name="series_id"
                        label="Series ID"
                        disabled={props.type === "update"}
                        fullWidth
                        sx={{ height: 45 }}
                        value={values.series_id}
                        onChange={(e) => {
                          handleChange(e);
                          getLatestSeriesID(e.target.value);
                        }}
                      >
                        {seriesID.map((series) => (
                          <MenuItem
                            key={series.series_id}
                            value={series.series_id}
                          >
                            {series.series_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                  {latestSeriesID && (
                    <MDBox p={1}>
                      Previous Catalogue ID : {latestSeriesID}
                    </MDBox>
                  )}
                  <MDBox p={1}>
                    <MDInput
                      type="text"
                      label="Catalogue Number"
                      value={values.catalogue_number}
                      name="catalogue_number"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <MDInput
                      type="number"
                      label="Max Quantity"
                      value={values.max_quantity}
                      name="max_quantity"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <MDInput
                      type="number"
                      label="MRP"
                      value={values.mrp}
                      name="mrp"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <MDInput
                      type="number"
                      label="Selling Price"
                      value={values.selling_price}
                      name="selling_price"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  <MDBox p={1}>
                    <MDInput
                      type="number"
                      label="Quantity Available"
                      value={values.qty_available}
                      name="qty_available"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox>
                  {/* <MDBox p={1}>
                    <MDInput
                      type="number"
                      label="Quantity Sold"
                      value={values.qty_sold}
                      name="qty_sold"
                      margin="dense"
                      fullWidth
                      onChange={handleChange}
                    />
                  </MDBox> */}
                </div>
                <MDBox p={1}>
                  <SelectArtist
                    selectedValues={artistsArray}
                    setSelectedValues={setArtistsArray}
                    name={"Select Artists"}
                  />
                </MDBox>
                <MDBox p={1}>
                  <SelectArtist
                    selectedValues={lyricistArray}
                    setSelectedValues={setLyricistArray}
                    name={"Select lyricists"}
                  />
                </MDBox>
                <MDBox p={1}>
                  <SelectArtist
                    selectedValues={mDirectorsArray}
                    setSelectedValues={setMDirectorsArray}
                    name={"Select Music Directors"}
                  />
                </MDBox>

                <MDBox p={1}>
                  <MDInput
                    type="text"
                    label="Country"
                    value={values.country}
                    name="country"
                    margin="dense"
                    fullWidth
                    onChange={handleChange}
                  />
                </MDBox>
                <MDBox p={1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    <FormControl style={{ flex: 1, marginRight: "15px" }}>
                      <InputLabel id="language">Language</InputLabel>
                      <Select
                        labelId="language"
                        id="language"
                        value={values.language}
                        name="language"
                        label="Language"
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        sx={{ height: 45 }}
                      >
                        <MenuItem value={"Hindi"}>Hindi</MenuItem>
                        <MenuItem value={"English"}>English</MenuItem>
                        <MenuItem value={"Marathi"}>Marathi</MenuItem>
                        <MenuItem value={"Punjabi"}>Punjabi</MenuItem>
                        <MenuItem value={"Bengali"}>Bengali</MenuItem>
                        <MenuItem value={"Tamil"}>Tamil</MenuItem>
                        <MenuItem value={"Telugu"}>Telugu</MenuItem>
                        <MenuItem value={"Malayalam"}>Malayalam</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem>
                      </Select>
                    </FormControl>

                    <RadioGroup
                      row
                      aria-labelledby="tag"
                      name="tag"
                      value={values.tag}
                      onClick={handleRadioClick}
                      // onChange={(e) => handleChange(e)}
                      style={{ marginRight: "10px" }}
                    >
                      <FormControlLabel
                        value="Prebooking"
                        control={<Radio />}
                        label="Prebooking"
                      />
                      <FormControlLabel
                        value="Bestseller"
                        control={<Radio />}
                        label="Bestseller"
                      />
                      <FormControlLabel
                        value="Rare"
                        control={<Radio />}
                        label="Rare"
                      />
                      <FormControlLabel
                        value="Coming Soon"
                        control={<Radio />}
                        label="Coming Soon"
                      />
                      <FormControlLabel
                        value="Latest Releases"
                        control={<Radio />}
                        label="Latest Releases"
                      />
                      <FormControlLabel
                        value="On Discount"
                        control={<Radio />}
                        label="On Discount"
                      />
                    </RadioGroup>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.combo === "true"}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "combo",
                                value: e.target.checked ? "true" : "false",
                              },
                            })
                          }
                          name="combo"
                        />
                      }
                      label="Combo"
                      style={{ flex: 1 }}
                    />
                  </div>
                </MDBox>

                <MDBox p={1}>
                  <MDTypography variant="button" color="dark">
                    Details
                  </MDTypography>
                  {detailsObject?.map((group, index) => (
                    <div key={index}>
                      <MDInput
                        label="Key"
                        value={group.key}
                        onChange={(e) =>
                          handleObjectChange(
                            setDetailsObject,
                            detailsObject,
                            index,
                            "key",
                            e.target.value
                          )
                        }
                        fullWidth
                      />
                      <MDInput
                        label="Description"
                        value={group.description}
                        multiline
                        rows={3}
                        onChange={(e) =>
                          handleObjectChange(
                            setDetailsObject,
                            detailsObject,
                            index,
                            "description",
                            e.target.value
                          )
                        }
                        fullWidth
                      />
                      <MDButton
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          removeObject(setDetailsObject, detailsObject, index)
                        }
                      >
                        Remove
                      </MDButton>
                    </div>
                  ))}
                  <div style={{ textAlign: "right" }}>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => addObject(setDetailsObject, detailsObject)}
                      sx={{ textAlign: "center" }}
                    >
                      Add
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox p={1}>
                  <MDTypography variant="button" color="dark">
                    Product Images
                  </MDTypography>
                  <MDBox
                    display="flex"
                    flexDirection={isSmallScreen ? "column" : "row"}
                    alignItems="center"
                    ml={-1}
                  >
                    {values.images.map((image, index) => (
                      <>
                        <Checkbox
                          icon={<DeleteOutlineOutlined />}
                          checkedIcon={<DeleteIcon />}
                          onChange={() => {
                            setTempImages(
                              tempImages.includes(image)
                                ? tempImages.filter((img) => img !== image)
                                : [...tempImages, image]
                            );
                          }}
                          sx={{ m: 0.5 }}
                        />
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          width="auto"
                          m={1}
                        >
                          <MDBox
                            component="img"
                            src={`https://pancham-bucket.s3.amazonaws.com/${image}`}
                            width="100px"
                            m={1}
                          />
                          <MDBox
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <MDButton
                              type="button"
                              onClick={() => {
                                if (index > 0) {
                                  handleSwap1(index, index - 1);
                                }
                              }}
                              disabled={index === 0}
                            >
                              {"<"}
                            </MDButton>
                            <MDButton
                              type="button"
                              onClick={() => {
                                if (index < tempImages.length - 1) {
                                  handleSwap1(index, index + 1);
                                }
                              }}
                              disabled={index === tempImages.length - 1}
                            >
                              {">"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </>
                    ))}
                  </MDBox>

                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={image}
                            alt={`Selected ${index + 1}`}
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              margin: "5px",
                            }}
                          />
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <MDButton
                              type="button"
                              onClick={() => {
                                if (index > 0) {
                                  handleSwap(index, index - 1);
                                }
                              }}
                              disabled={index === 0}
                            >
                              {"<"}
                            </MDButton>
                            <MDButton
                              type="button"
                              onClick={() => {
                                if (index < selectedImages.length - 1) {
                                  handleSwap(index, index + 1);
                                }
                              }}
                              disabled={index === selectedImages.length - 1}
                            >
                              {">"}
                            </MDButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </MDBox>
                <MDBox p={1}>
                  <MDTypography variant="button" color="dark">
                    {values.product_type === "Vinyl Record"
                      ? "Records"
                      : values.product_type === "CD"
                      ? "CDs"
                      : "Cassetes"}
                  </MDTypography>
                </MDBox>
                {values.cd_details && (
                  <MDBox p={2}>
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={values.cd_details}
                      columns={columns}
                      disableRowSelectionOnClick
                    />
                  </MDBox>
                )}

                <MDBox p={1}>
                  <MDTypography variant="button" color="dark">
                    Product Catalogue
                  </MDTypography>
                </MDBox>
                {values.catalogue && (
                  <MDBox p={2}>
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={values.catalogue}
                      columns={catalogueColumns}
                      disableRowSelectionOnClick
                    />
                  </MDBox>
                )}

                {localStorage.getItem("role") === "admin" && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    p={2}
                    pb={0.5}
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={20} sx={{ color: "inherit" }} />
                      ) : props.type === "update" ? (
                        "Update Product"
                      ) : (
                        "Create Product"
                      )}
                    </MDButton>
                  </Grid>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddProduct;
