// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import JoinMembership from "layouts/Membership/JoinMembership";
import MemberShip from "layouts/Membership/membership";
import AddProduct from "layouts/chamber/AddProduct";
import Chamber from "layouts/chamber";

// @mui icons
import Icon from "@mui/material/Icon";
import CDs from "layouts/chamber/CDs";
import DuplicateProduct from "layouts/chamber/AddProduct/DuplicateProduct";
import StoreSale from "layouts/storesale/StoreSale";
import AllOrders from "layouts/orders/Orders";
import SingleOrder from "layouts/orders/SingleOrder";
import AllUsers from "layouts/users/AllUsers";
import SingleUser from "layouts/users/SingleUser";
import AllArtists from "layouts/artists/AllArtists";
import AddArtist from "layouts/artists/AddArtist";
import AllSeries from "layouts/series/AllSeries";
import AddSeries from "layouts/series/AddSeries";
import SingleSeries from "layouts/series/SingleSeries";

const routes = () => {
  
  const adminRoutes = [
    {
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      // type: "collapse",
      name: "Tables",
      key: "tables",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/tables",
      component: <Tables />,
    },
    {
      // type: "collapse",
      name: "Billing",
      key: "billing",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/billing",
      component: <Billing />,
    },
    {
      // type: "collapse",
      name: "RTL",
      key: "rtl",
      icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
      route: "/rtl",
      component: <RTL />,
    },
    {
      type: "collapse",
      name: "Products",
      key: "product",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/products",
      component: <Chamber />,
    },
    {
      key: "add_product",
      route: "/products/add",
      component: <AddProduct props={{ type: "add" }} />,
    },
    {
      key: "add_product",
      route: "/products/duplicate/:id",
      component: <DuplicateProduct props={{ type: "update" }} />,
    },
    {
      key: "update_product",
      route: "/products/:id",
      component: <AddProduct props={{ type: "update" }} />,
    },
    {
      key: "media",
      route: "/products/:id/media",
      component: <CDs props={{ type: "add" }} />,
    },
    {
      type: "collapse",
      name: "Orders",
      key: "orders",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/orders",
      component: <AllOrders />,
    },
    {
      key: "update_order",
      route: "/orders/:id",
      component: <SingleOrder props={{ type: "update" }} />,
    },

    {
      type: "collapse",
      name: "Users",
      key: "users",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/users",
      component: <AllUsers />,
    },
    {
      key: "update_user",
      route: "/users/:id",
      component: <SingleUser props={{ type: "update" }} />,
    },
    {
      type: "collapse",
      name: "Artists",
      key: "artists",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/artists",
      component: <AllArtists />,
    },
    {
      key: "add_artist",
      route: "/artists/add",
      component: <AddArtist />,
    },

    {
      type: "collapse",
      name: "Store Sale",
      key: "store_sale",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/store-sale",
      component: <StoreSale />,
    },
    {
      type: "collapse",
      name: "Series",
      key: "series",
      icon: <Icon fontSize="small">receipt_long</Icon>,
      route: "/series",
      component: <AllSeries />,
    },
    {
      key: "add_series",
      route: "/series/add",
      component: <AddSeries />,
    },
    {
      key: "view_series",
      route: "/series/:id",
      component: <SingleSeries />,
    },
    {
      // type: "collapse",
      name: "Membership",
      key: "membership",
      icon: <Icon fontSize="small">handshake</Icon>,
      route: "/membership",
      component: <MemberShip />,
    },
    {
      key: "create_membership",
      route: "/membership/add",
      component: <JoinMembership />,
    },
    {
      // type: "collapse",
      name: "Notifications",
      key: "notifications",
      icon: <Icon fontSize="small">notifications</Icon>,
      route: "/notifications",
      component: <Notifications />,
    },
    {
      // type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <Profile />,
    },
    {
      // type: "collapse",
      name: "Sign In",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/sign-in",
      component: <SignIn />,
    },
  ];

    const managerRoutes = [
      {
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <Dashboard />,
      },
      {
        // type: "collapse",
        name: "Tables",
        key: "tables",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/tables",
        component: <Tables />,
      },
      {
        // type: "collapse",
        name: "Billing",
        key: "billing",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/billing",
        component: <Billing />,
      },
      {
        // type: "collapse",
        name: "RTL",
        key: "rtl",
        icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
        route: "/rtl",
        component: <RTL />,
      },
      {
        type: "collapse",
        name: "Products",
        key: "product",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/products",
        component: <Chamber />,
      },
      {
        key: "add_product",
        route: "/products/add",
        component: <AddProduct props={{ type: "add" }} />,
      },
      {
        key: "add_product",
        route: "/products/duplicate/:id",
        component: <DuplicateProduct props={{ type: "update" }} />,
      },
      {
        key: "update_product",
        route: "/products/:id",
        component: <AddProduct props={{ type: "update" }} />,
      },
      {
        key: "media",
        route: "/products/:id/media",
        component: <CDs props={{ type: "add" }} />,
      },
      {
        type: "collapse",
        name: "Orders",
        key: "orders",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/orders",
        component: <AllOrders />,
      },
      {
        key: "update_order",
        route: "/orders/:id",
        component: <SingleOrder props={{ type: "update" }} />,
      },

      {
        type: "collapse",
        name: "Users",
        key: "users",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/users",
        component: <AllUsers />,
      },
      {
        key: "update_user",
        route: "/users/:id",
        component: <SingleUser props={{ type: "update" }} />,
      },

      {
        type: "collapse",
        name: "Store Sale",
        key: "store_sale",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/store-sale",
        component: <StoreSale />,
      },
      {
        type: "collapse",
        name: "Series",
        key: "series",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/series",
        component: <AllSeries />,
      },
      {
        key: "add_series",
        route: "/series/add",
        component: <AddSeries />,
      },
      {
        key: "view_series",
        route: "/series/:id",
        component: <SingleSeries />,
      },

      {
        // type: "collapse",
        name: "Notifications",
        key: "notifications",
        icon: <Icon fontSize="small">notifications</Icon>,
        route: "/notifications",
        component: <Notifications />,
      },
      {
        // type: "collapse",
        name: "Profile",
        key: "profile",
        icon: <Icon fontSize="small">person</Icon>,
        route: "/profile",
        component: <Profile />,
      },
      {
        // type: "collapse",
        name: "Sign In",
        key: "sign-in",
        icon: <Icon fontSize="small">login</Icon>,
        route: "/sign-in",
        component: <SignIn />,
      },
    ];
  

  const notLoggedInRoutes = [
    {
      type: "collapse",
      name: "Sign In",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/sign-in",
      component: <SignIn />,
    },
  ]

  return {
    adminRoutes,
    notLoggedInRoutes,
    managerRoutes
  };
};
export default routes;
