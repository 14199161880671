import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  Card,
  CircularProgress,
  Grid,
  FormControlLabel,
  Typography,
  TextField,
  Chip,
  Button,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Checkbox from "@mui/material/Checkbox";
import SelectArtist from "components/SelectArtist/SelectArtist";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { DataGrid } from "@mui/x-data-grid";
import {
  DeleteForeverOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import StepLabel from "@mui/material/StepLabel";

import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";

function CDs({ props }) {
  const location = useLocation();
  const cdDetails = location.state?.cdDetails;
  const cdType = location.state?.type ?? "";

  const initialState = {
    user_id: 2,
    product_id: 0,
    name: "",
    data: [
      {
        title: "",
        singers: [],
        lyricists: [],
        music_directors: [],
        side: "A",
        index: 0,
      },
    ],
  };

  const [formData, setFormData] = useState({
    user_id: 2,
    product_id: 0,
    name: "",
    data: [
      {
        title: "",
        singers: [],
        lyricists: [],
        music_directors: [],
        side: "A",
        index: 0,
      },
    ],
  });

  const handleRemoveSong = async (indexToRemove) => {
    setFormData((prevFormData) => {
      const updatedData = prevFormData.data.filter(
        (_, index) => index !== indexToRemove
      );
      return { ...prevFormData, data: updatedData };
    });

    if (id && cdDetails.tracks) {
      setLoading(true);
      console.log(cdDetails);

      const modifiedFormData = {
        id: cdDetails.tracks[indexToRemove].id,
      };

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/admin/product/deletetrack`,
        data: modifiedFormData,
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((res) => {
          if (res.data.success) {
            enqueueSnackbar(res.data.data, {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Operation failed", { variant: "error" });
          }
        })
        .catch((err) => {
          console.error("Error occurred:", err);
          enqueueSnackbar("Error occurred. Please try again.", {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleInputChange = (event, index, field) => {
    const newData = [...formData.data];
    newData[index][field] = event.target.value;
    setFormData({ ...formData, data: newData });
  };

  const handleArtistSelection = (index, field, selectedValues) => {
    const newData = [...formData.data];
    newData[index][field] = selectedValues;
    setFormData({ ...formData, data: newData });
  };

 const handleAddSong = () => {
   setFormData((prevFormData) => {
     const data = prevFormData?.data || [];
     const newIndex = data.length;

     return {
       ...prevFormData,
       data: [
         ...data,
         {
           title: "",
           singers: [],
           lyricists: [],
           music_directors: [],
           side: "A",
           index: newIndex,
         },
       ],
     };
   });
 };

   const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateName = async () => {
    setIsUpdating(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/product/updatecdname`,
        {
          id: cdDetails?.id,
          name: formData.name,
        }
      );

      if (response.data.success) {
        alert("Name updated successfully!");
      }
    } catch (error) {
      console.error("Error updating name", error);
    } finally {
      setIsUpdating(false);
    }
  };


  const { id } = useParams();
  console.log(id);
  const auth = localStorage.getItem("authToken");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cdsCount, setCdsCount] = useState(0);
  const [currentCdCount, setCurrentCdCount] = useState(0);

  useEffect(() => {
    if (id) {
      setFormData({
        ...formData,
        product_id: id,
      });
    }
    if (cdDetails) {
      setFormData({
        ...formData,
        // data: cdDetails?.tracks?.reverse().map((track) => ({
        data: cdDetails?.tracks?.map((track) => ({
          ...track,
          singers: track?.singers
            ? track.singers.map((singer) => ({ name: singer }))
            : [],
          lyricists: track?.lyricists
            ? track.lyricists.map((lyricist) => ({ name: lyricist }))
            : [],
          music_directors: track?.music_directors
            ? track.music_directors.map((director) => ({ name: director }))
            : [],
        })),
        name: cdDetails?.name,
      });
    }

    if (!cdDetails && !cdsCount) {
      const count = prompt("Enter Media Count");
      if (count === null) {
        navigate(`/products/${id}`);
      } else {
        setCdsCount(parseInt(count));
      }
    }
  }, [id]);

  const getProduct = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/product/getproduct`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);

          enqueueSnackbar("Added successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Failed", { variant: "error" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const handleUpdateTrack = async (index) => {
    setLoading(true);
    const trackToUpdate = formData.data[index];
    const getTrackIdAtIndex = (cdDetails, index) =>
      cdDetails?.tracks?.[index]?.id ?? null;

    const modifiedFormData = {
      product_id: id,
      id: cdDetails.tracks ? cdDetails.tracks[index].id : null,
      singers: trackToUpdate?.singers?.map((singer) => singer.name),
      lyricists: trackToUpdate?.lyricists?.map((lyricist) => lyricist.name),
      music_directors: trackToUpdate?.music_directors?.map(
        (director) => director.name
      ),
      side: trackToUpdate?.side,
      title: trackToUpdate?.title,
      index: trackToUpdate?.index,
      cd_id: cdDetails?.id,
    };

    await axios({
      method:  "post",
      url: `${process.env.REACT_APP_BASEURL}/admin/product/updatetrack`,
      data: modifiedFormData,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(res.data.message, {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Operation failed", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error("Error occurred:", err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const modifiedFormData = {
      ...formData,
      product_id: id,
      data: formData?.data?.map((song) => ({
        ...song,
        singers: song?.singers?.map((singer) => singer.name),
        lyricists: song?.lyricists?.map((lyricist) => lyricist.name),
        music_directors: song?.music_directors?.map(
          (director) => director.name
        ),
      })),
    };

    await axios({
      method: !id ? "put" : "post",
      url: `${process.env.REACT_APP_BASEURL}/admin/product/${
        !id ? "updateproduct" : "addcd"
      }`,
      data: modifiedFormData,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Operation successful", {
            variant: "success",
          });
          setCurrentCdCount((prevCount) => prevCount + 1);
          if (currentCdCount + 1 === cdsCount) {
            setTimeout(() => {
              alert("Operation successful...!!!");
              navigate(`/products/${id}`);
            }, 0);
          }
          setFormData(initialState);
        } else {
          enqueueSnackbar("Operation failed", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error("Error occurred:", err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />

      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {id ? `Update ${cdType}` : `Create ${cdType}`}
                </MDTypography>
              </MDBox>

              <MDBox p={3} component="form" role="form">
                <div>
                  <Stepper activeStep={currentCdCount} alternativeLabel>
                    {[...Array(cdsCount)]?.map((_, index) => (
                      <Step key={index}>
                        <StepLabel>{`${cdType} ${index + 1}`}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <MDBox p={1}>
                    <MDTypography sx={{ textAlign: "center" }} variant="h5">
                      {`${cdType} ${currentCdCount + 1}`}
                    </MDTypography>
                  </MDBox>
                  <MDBox p={1} display="flex" alignItems="center">
                    <MDInput
                      type="text"
                      label="Title"
                      value={formData.name}
                      name="title"
                      margin="dense"
                      fullWidth
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                    <IconButton
                      onClick={handleUpdateName}
                      disabled={isUpdating}
                      sx={{ ml: 1 }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </MDBox>
                </div>

                <Grid container spacing={2}>
                  {formData?.data?.map((song, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper style={{ padding: 16 }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          p={2}
                          pb={0.5}
                          item
                          xs={12}
                        >
                          <Typography variant="h6" gutterBottom>
                            Song {index + 1}
                          </Typography>
                          <MDButton
                            variant="contained"
                            color="primary"
                            onClick={() => handleRemoveSong(index)}
                          >
                            Remove
                          </MDButton>
                        </Grid>

                        <TextField
                          label="Title"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={song.title}
                          onChange={(e) => handleInputChange(e, index, "title")}
                        />
                        <SelectArtist
                          name="Singers"
                          selectedValues={song.singers}
                          setSelectedValues={(selectedValues) =>
                            handleArtistSelection(
                              index,
                              "singers",
                              selectedValues
                            )
                          }
                        />
                        <SelectArtist
                          name="Lyricists"
                          selectedValues={song.lyricists}
                          setSelectedValues={(selectedValues) =>
                            handleArtistSelection(
                              index,
                              "lyricists",
                              selectedValues
                            )
                          }
                        />
                        <SelectArtist
                          name="Music Directors"
                          selectedValues={song.music_directors}
                          setSelectedValues={(selectedValues) =>
                            handleArtistSelection(
                              index,
                              "music_directors",
                              selectedValues
                            )
                          }
                        />
                        <FormControl fullWidth>
                          <InputLabel id="dropdown-label">
                            Select Side
                          </InputLabel>
                          <Select
                            labelId="dropdown-label"
                            id="dropdown"
                            value={song.side}
                            onChange={(e) =>
                              handleInputChange(e, index, "side")
                            }
                            fullWidth
                            label="Select Side"
                            sx={{ height: 45 }}
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="B">B</MenuItem>
                          </Select>
                        </FormControl>
                        <MDButton
                          style={{ margin: "10px" }}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleUpdateTrack(index)}
                        >
                          Add/Update
                        </MDButton>
                      </Paper>
                    </Grid>
                  ))}
                  <Grid container p={2} item xs={12}>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={handleAddSong}
                    >
                      Add Song
                    </MDButton>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  p={2}
                  pb={0.5}
                >
                  { id && !cdDetails?.id &&
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={20} sx={{ color: "inherit" }} />
                      ) : 
                        `Create ${cdType}`
                      }
                    </MDButton>
                  }
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CDs;
