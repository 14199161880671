import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Grid,
  FormControlLabel,
  TextField,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";

// Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import InvoiceGenerator from "./InvoiceGenerator";

function SingleOrder({ props }) {
  const { id } = useParams();
  const auth = localStorage.getItem("authToken");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState([]);
  const [catalogueIds, setCatalogueIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [shipment, setShipment] = useState({
    link: "",
    tracking_id: "",
    status: "",
    o_date: "",
    catalogue_id: [],
    delivery_type: "domestic",
    order_id: parseInt(id),
    user_id: 2,
  });

  useEffect(() => {
    if (id) {
      getOrder();
    }
    if (auth) {
      const decodedJwt = JSON.parse(atob(auth.split(".")[1]));
      if (decodedJwt.exp * 1000 < Date.now()) {
        navigate("/sign-in");
      }
    }
  }, [id, auth]);

  const getOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/getorder`,
        {
          order_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        setValues(response.data.data.orders);
        setIsEditable(
          new Array(response.data.data.orders.shipments.length).fill(false)
        );
        enqueueSnackbar("Order Fetched successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const createShipments = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/addshipment`,
        {
          order_id: parseInt(id),
          user_id: 2,
          delivery_type: values?.address_details.type,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar("Order Created successfully", {
          variant: "success",
        });
        getOrder();
      } else {
        enqueueSnackbar("Failed", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

const handleChange = (e, index) => {
  const { name, value } = e.target;
  setValues((prev) => ({
    ...prev,
    shipments: prev.shipments.map((shipment, i) =>
      i === index
        ? {
            ...shipment,
            shipment_details: {
              ...shipment.shipment_details,
              [name]: value,
            },
          }
        : shipment
    ),
  }));
};

  const handleUpdate = (index) => {
    const newIsEditable = [...isEditable];
    newIsEditable[index] = true;
    setIsEditable(newIsEditable);
  };

  const handleSave = async (index) => {
    const newIsEditable = [...isEditable];
    newIsEditable[index] = false;
    setIsEditable(newIsEditable);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/updateshipment`,
        {
          shipment_id: values.shipments[index].shipment_details.shipment_id,
          link: values.shipments[index].shipment_details.link,
          tracking_id: values.shipments[index].shipment_details.tracking_id,
          o_date: values.shipments[index].shipment_details.o_date,
          status: values.shipments[index].shipment_details.status,
          user_id: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar("Shipment updated successfully", {
          variant: "success",
        });
        getOrder(); // Refresh the order data after updating shipment
      } else {
        enqueueSnackbar("Failed to update shipment", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeShipment = (e) => {
    const { name, value } = e.target;
    setShipment((prev) => ({ ...prev, [name]: value }));
  };

  const handleCatalogueChange = (e) => {
    setShipment((prev) => ({ ...prev, catalogue_id: e.target.value }));
  };

    const getCatalogueIDs = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/order/getcataloguebyorderid`,
          {
            order_id: id,
            user_id: 2
          },
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
        if (response.data.success) {
            const ids = response.data.data.map((item) => item.catalogue_id);
            setCatalogueIds(ids);
          setIsOpen(true);
        } else {
          enqueueSnackbar("Failed to receive catalogue Ids", { variant: "error" });
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

  const createShipment = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/order/createashipment`,
        shipment
      );
      console.log(response.data)
      window.location.reload()
    } catch (error) {
      console.error("Failed to create shipment:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar hidebreadcrumbTitle />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <MDBox pt={3} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="h6" color="white">
                      Order Details
                    </MDTypography>
                    {/* {values &&
                      values.shipments &&
                      values.shipments.length === 0 && ( */}
                    <MDButton
                      // onClick={() => createShipments()}
                      onClick={() => getCatalogueIDs()}
                      variant="outlined"
                      color="white"
                    >
                      Create Shipment
                    </MDButton>
                    <InvoiceGenerator id={id} />
                    {/* )} */}
                  </MDBox>

                  {values && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <Grid
                            container
                            spacing={3}
                            style={{ padding: "20px" }}
                          >
                            <Grid item xs={12}>
                              <MDTypography variant="h5">
                                Order Details
                              </MDTypography>
                              <MDTypography variant="h6">
                                Order ID: {values.billing_id}
                              </MDTypography>

                              <MDTypography variant="h6">
                                ID: {values.order_id}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Order Value: ₹ {values.billing_amount}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <MDTypography variant="h5">
                                User Details
                              </MDTypography>
                              <MDTypography variant="h6">
                                Full Name: {values.full_name}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Mobile Number: {values.mobile_no}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <MDTypography variant="h5">
                                Address Details
                              </MDTypography>
                              <MDTypography variant="h6">
                                Type: {values?.address_details.type}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Name: {values.address_details.name}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Street 1: {values.address_details.street1}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Street 2: {values.address_details.street2}
                              </MDTypography>
                              <MDTypography variant="h6">
                                City: {values.address_details.city}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Pincode: {values.address_details.pincode}
                              </MDTypography>
                              <MDTypography variant="h6">
                                Mobile Number:{" "}
                                {values.address_details.mobile_no}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12}>
                              <MDTypography variant="h5">
                                Order Items
                              </MDTypography>
                              {values.order_items.map((item) => (
                                <div
                                  key={item.order_item_id}
                                  style={{ marginBottom: "20px" }}
                                >
                                  <MDTypography variant="h6">
                                    Product Title: {item.product_details.title}
                                  </MDTypography>
                                  <MDTypography variant="h6">
                                    Type: {item.product_details.product_type}
                                  </MDTypography>
                                  <MDTypography variant="h6">
                                    Condition :{" "}
                                    {item.product_details.condition_type}
                                  </MDTypography>
                                  <MDTypography variant="h6">
                                    Quantity: {item.quantity}
                                  </MDTypography>
                                  <MDTypography variant="h6">
                                    Total Price: ₹ {item.total_price}
                                  </MDTypography>
                                  <MDTypography variant="h6">
                                    Catalogue IDs:{" "}
                                    {item.product_details.catalogue_details
                                      .map((item) => item.catalogue_id)
                                      .join(", ")}
                                  </MDTypography>
                                  {/* Add more details as needed */}
                                </div>
                              ))}
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          {isOpen && (
            <MDBox pt={3} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="secondary"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Create Shipment
                      </MDTypography>
                    </MDBox>

                    <Grid container spacing={3} style={{ padding: "35px" }}>
                      <MDBox p={1} width="100%">
                        <FormControl fullWidth>
                          <InputLabel id="dropdown-label">
                            Select Courier
                          </InputLabel>
                          <Select
                            labelId="dropdown-label"
                            id="dropdown"
                            value={shipment.link}
                            name="link"
                            onChange={handleChangeShipment}
                            fullWidth
                            label="Select Courier"
                            sx={{ height: 45 }}
                          >
                            <MenuItem value="DTDC">DTDC</MenuItem>
                            <MenuItem value="Blue Dart">Blue Dart</MenuItem>
                            <MenuItem value="Shree Anjani Courier">
                              Shree Anjani Courier
                            </MenuItem>
                            <MenuItem value="Track On Courier">
                              Track On Courier
                            </MenuItem>
                            <MenuItem value="Shree Mahavir Courier">
                              Shree Mahavir Courier
                            </MenuItem>
                            <MenuItem value="Shree Maruti Courier">
                              Shree Maruti Courier
                            </MenuItem>
                            <MenuItem value="Speedpost">Speedpost</MenuItem>
                            <MenuItem value="Professional Courier">
                              Professional Courier
                            </MenuItem>
                            <MenuItem value="Sky King Courier">
                              Sky King Courier
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox p={1} width="100%">
                        <MDInput
                          type="text"
                          label="Tracking ID"
                          value={shipment.tracking_id}
                          name="tracking_id"
                          onChange={handleChangeShipment}
                          fullWidth
                        />
                      </MDBox>
                      <MDBox p={1} width="100%">
                        <MDInput
                          type="date"
                          label="Shipment Date"
                          value={shipment.o_date}
                          name="o_date"
                          onChange={handleChangeShipment}
                          fullWidth
                        />
                      </MDBox>
                      <MDBox p={1} width="100%">
                        <FormControl fullWidth>
                          <InputLabel>Catalogue IDs</InputLabel>
                          <Select
                            multiple
                            value={shipment.catalogue_id}
                            onChange={handleCatalogueChange}
                            name="catalogue_id"
                            sx={{ height: "50px" }}
                          >
                            {catalogueIds.map((id) => (
                              <MenuItem key={id} value={id}>
                                {id}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </MDBox>
                      <MDBox p={1} width="100%">
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={shipment.status}
                            onChange={handleChangeShipment}
                            name="status"
                            sx={{ height: "50px" }}
                          >
                            <MenuItem value="shipped">Shipped</MenuItem>
                            <MenuItem value="delivered">Delivered</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>

                      <MDBox p={1}>
                        <MDButton
                          onClick={() => createShipment()}
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                        >
                          {isLoading ? "Creating..." : "Create Shipment"}
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          )}

          <MDBox pt={3} pb={3}>
            <Grid container spacing={6}>
              {values &&
                values.shipments &&
                values.shipments.map((shipment, index) => (
                  <Grid item xs={12} key={index}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="secondary"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDTypography variant="h6" color="white">
                          Shipment {index + 1}
                        </MDTypography>
                        <MDButton
                          onClick={() => handleUpdate(index)}
                          variant="outlined"
                          color="white"
                        >
                          Update
                        </MDButton>
                      </MDBox>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card variant="outlined">
                            <Grid
                              container
                              spacing={3}
                              style={{ padding: "35px" }}
                            >
                              <Grid item xs={12}>
                                {/* <MDTypography variant="h6">
                                  Order ID: {values.order_id}
                                </MDTypography> */}
                                <MDTypography variant="h6">
                                  Shipment ID:{" "}
                                  {shipment.shipment_details.shipment_track}
                                </MDTypography>
                                {/* {values.order_items &&
                                  values.order_items.map((item, idx) => (
                                    <MDTypography key={idx} variant="h6">
                                      Catalogue IDs :{" "}
                                      {JSON.parse(
                                        shipment.shipment_details.catalogue_id
                                      ).join(",")}
                                    </MDTypography>
                                    ))} */}
                                <MDTypography variant="h6">
                                  Catalogue IDs :{" "}
                                  {JSON.parse(
                                    shipment.shipment_details.catalogue_id
                                  ).join(",")}
                                </MDTypography>
                                {/* <MDTypography variant="h6">
                                  Shipping Charges: ₹
                                  {shipment.shipment_details.delivery_charges ||
                                    "N/A"}
                                </MDTypography> */}
                              </Grid>

                              <MDBox p={1} width="100%">
                                <FormControl fullWidth>
                                  <InputLabel id="dropdown-label">
                                     Courier Service
                                  </InputLabel>
                                  <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={shipment.shipment_details.link || ""}
                                    name="link"
                                    disabled={!isEditable[index]}
                                    onChange={(e) => handleChange(e, index)}
                                    fullWidth
                                    label="Select Courier"
                                    sx={{ height: 45 }}
                                  >
                                    <MenuItem value="DTDC">DTDC</MenuItem>
                                    <MenuItem value="Blue Dart">
                                      Blue Dart
                                    </MenuItem>
                                    <MenuItem value="Shree Anjani Courier">
                                      Shree Anjani Courier
                                    </MenuItem>
                                    <MenuItem value="Track On Courier">
                                      Track On Courier
                                    </MenuItem>
                                    <MenuItem value="Shree Mahavir Courier">
                                      Shree Mahavir Courier
                                    </MenuItem>
                                    <MenuItem value="Shree Maruti Courier">
                                      Shree Maruti Courier
                                    </MenuItem>
                                    <MenuItem value="Speedpost">
                                      Speedpost
                                    </MenuItem>
                                    <MenuItem value="Professional Courier">
                                      Professional Courier
                                    </MenuItem>
                                    <MenuItem value="Sky King Courier">
                                      Sky King Courier
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </MDBox>

                              {/* <MDBox p={1} width="100%">
                                <MDInput
                                  type="text"
                                  label="Link"
                                  value={shipment.shipment_details.link || ""}
                                  name="link"
                                  margin="dense"
                                  fullWidth
                                  disabled={!isEditable[index]}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </MDBox> */}
                              <MDBox p={1} width="100%">
                                <MDInput
                                  type="date"
                                  label="Shipment Date"
                                  value={
                                    shipment.shipment_details.o_date &&
                                    !isNaN(
                                      new Date(shipment.shipment_details.o_date)
                                    )
                                      ? new Date(
                                          shipment.shipment_details.o_date
                                        )
                                          .toISOString()
                                          .slice(0, 10)
                                      : ""
                                  }
                                  name="o_date"
                                  onChange={(e) => handleChange(e, index)}
                                  disabled={!isEditable[index]}
                                  fullWidth
                                />
                              </MDBox>
                              <MDBox p={1} width="100%">
                                <MDInput
                                  type="text"
                                  label="Tracking ID"
                                  value={
                                    shipment.shipment_details.tracking_id || ""
                                  }
                                  name="tracking_id"
                                  margin="dense"
                                  fullWidth
                                  disabled={!isEditable[index]}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </MDBox>
                              <MDBox p={1} width="100%">
                                <FormControl
                                  fullWidth
                                  disabled={!isEditable[index]}
                                >
                                  <InputLabel>Status</InputLabel>
                                  <Select
                                    value={
                                      shipment.shipment_details.status || ""
                                    }
                                    onChange={(e) => handleChange(e, index)}
                                    name="status"
                                    style={{ height: "50px" }}
                                  >
                                    <MenuItem value="shipped">Shipped</MenuItem>
                                    {/* <MenuItem value="pending">Pending</MenuItem> */}
                                    <MenuItem value="delivered">
                                      Delivered
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </MDBox>
                              {isEditable[index] && (
                                <MDBox p={1}>
                                  <MDButton
                                    onClick={() => handleSave(index)}
                                    variant="contained"
                                    color="primary"
                                  >
                                    Update Shipment
                                  </MDButton>
                                </MDBox>
                              )}
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
}

export default SingleOrder;
